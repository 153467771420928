h1 {
    display: flex;
    justify-content: center;
    color: pink;
}

form {
    display: flex;
    justify-content: center;
}

li {
    display: flex;
    justify-content: center;
    color: pink;
}

input[type="checkbox" i] {
    display: flex;
    justify-content: center;
}